jQuery(function($){
    let start; 

    $('.bxslider_item').hide();
    $('.bxslider_item').first().show()

    start = setInterval(slideshow, 4000);
    let curr = $('.bxslider_item').first();
    
    function slideshow(){
        $('.bxslider_item').hide();
        if(curr.index() == $('.bxslider_item').last().index()){
            curr = $('.bxslider_item').first();
        } else{
            curr = curr.next();
        }

        curr.fadeIn(500)
    }
})